.page-header {
  padding: 50px;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 2rem;
  width: 40%;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  /* transform: translate(0, -75px); */
  font-weight: bold;
}

.header-long {
  width: 70%;
}

@media only screen and (max-width: 600px) {
  .page-header {
    width: 90%;
  }

  .header-long {
    width: 90%;
    font-size: 1.5rem;
  }
}
