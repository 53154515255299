h1,
p {
  margin: 0;
}

.article-wrapper {
  width: 60%;
  padding-bottom: 30px;
}

.article-image {
  width: 50%;
  pointer-events: none;
}

.single-article-wrapper {
  display: flex;
  background-color: white;
  justify-content: space-between;
  margin-top: 45px;
  border: solid #747779 1px;
  color: black;
}

.blog-wrapper {
  transition: transform 0.2s;
}

.blog-wrapper :hover {
  box-shadow: 0 4px 8px 0 black;
  transform: scale(1.01);
}

.article-text {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  pointer-events: none;
}

.article-author {
  font-size: 1.1rem;
}

.author-name-single {
  font-size: 1.1rem;
}

.article-date {
  /* margin-left: 10px; */
  font-size: 0.8rem;
}

.article-title {
  font-size: 1.6rem;
  line-height: 1.3;
  margin-top: 10px;
  padding-right: 4px;
  font-weight: bold;
}

.article-body {
  font-size: 0.9rem;
  margin-top: 15px;
  text-overflow: ellipsis;
}

/* SIngle Article page */
.article-page-wrapper {
  width: 70%;
}

.single-article-image {
  margin-top: 25px;
  width: 55%;
  border-radius: 8px;
  height: auto;
  /* margin-left: 15%; */
}

.article-page-header {
  display: flex;
  font-size: 0.9rem;
  margin-top: 2%;
  flex-direction: column;
  align-items: center;
}

.article-page-title {
  font-size: 2.2rem;
  line-height: 1.8;
  margin-top: 15px;
}

.article-page-description {
  font-size: 2rem;
  line-height: 2;
  margin-top: 15px;
}
@media only screen and (max-width: 600px) {
  .article-wrapper {
    width: 100%;
    margin: 0;
  }

  .single-article-wrapper {
    display: block;
  }

  .article-image {
    width: 100%;
  }

  .article-text {
    margin-top: 10px;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    padding-bottom: 5px;
  }

  .article-title {
    font-size: 1rem;
  }

  .article-body {
    font-size: 0.8rem;
    margin-top: 15px;
  }

  /* SIngle Article page */
  .article-page-wrapper {
    width: 90%;
  }

  .article-page-title {
    font-size: 1.5rem;
    line-height: 1.4;
  }

  .article-page-description {
    font-size: 1rem;
  }
}
